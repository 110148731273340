<template>
	<div v-if="canMarkAsSeen">
		<w-btn v-if="simpleButtonDisplay && !listTileDisplay" flat icon="visibility" mini :loading="isLoading" @click="markAsSeen()">
			{{ $tc('ecm.mark_as_read', total) }}
		</w-btn>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="markAsSeen()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">visibility</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ $tc('ecm.mark_as_read', total) }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import DocumentsCounterModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsCounterModuleGuard'

export default {
	name: 'CounterButton',
	mixins: [DocumentsCounterModuleGuard],
	props: {
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			isLoading: false
		}
	},
	computed: {
		nodes: function () {
			let result = []
			if (this.value) {
				result = Array.isArray(this.value) ? this.value : [this.value]
			}
			return result
		},
		total: function () {
			return this.nodes
				.filter(node => node.counter > 0)
				.map(node => node.counter)
				.reduce((counter, total) => total + counter, 0)
		},
		canMarkAsSeen: function () {
			return this.total > 0
		}
	},
	methods: {
		markAsSeen: function () {
			this.isLoading = true
			const totalBeforeAction = this.total
			const nodes = [...this.nodes]
			const promises = []
			nodes.forEach(node => {
				this.eventBus.emit(this.events.RESETING_COUNTER, node)
				promises.push(this.service.deleteNodeCounter(this.accountingFirmId, this.vendorId, node))
			})
			Promise.all(promises)
				.then(() => {
					nodes.forEach(node => {
						this.eventBus.emit(this.events.RESET_COUNTER, node)
					})
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('ecm.marked_as_read', totalBeforeAction))
				})
				.finally(() => {
					nodes.forEach(node => {
						this.eventBus.emit(this.events.RESETING_COUNTER_ENDED, node)
					})
					this.isLoading = false
				})
		}
	}
}
</script>
